import styles from './style.module.scss';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState, forwardRef} from "react";

const InputField = forwardRef(({
    children,
    placeholder,
    className,
    type,
    name,
    defaultValue,
    onChange,
    onClick,
    autoComplete,
    disabled,
    bgColor,
    borderColor
                    }, ref) => {
    const [inputType, setInputType] = useState(type);
    const handleTypeChange = () => {
        if(inputType==="password")
        {
            setInputType("text")
            return;
        }
        setInputType("password")
    }

    return (
        <div className={styles.inputDiv} style={{
            background:`${bgColor}`,
            border: `1px solid ${borderColor}`
        }}>
            {type === "Price" && "AMD" }
        <input
            type={type === "Price" ? "number" : inputType}
            name={name}
            autoComplete={autoComplete}
            onChange={onChange}
            onClick={onClick}
            className={styles.input + " " + className }
            defaultValue= {defaultValue}
            placeholder= {placeholder}
            ref= {ref}
            disabled= {disabled}
            readOnly={false}

        >
            {children}
        </input>
            {type === 'password' && <span onClick={handleTypeChange}>{inputType === 'text' ? <VisibilityOff/> : <Visibility/> }</span>}
        </div>
    )
});

export default InputField;