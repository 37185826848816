import styles from './style.module.scss';
import {Link, useNavigate} from "react-router-dom";
import {gql, useMutation, useQuery, useSubscription} from "@apollo/client";
import {useEffect, useRef, useState} from "react";
import NotificationSound from "../../../assets/NotificationSounds/notification-sound.mp3"
import ReminderSound from "../../../assets/NotificationSounds/reminder_sound.mp3";
import DeliveredSound from "../../../assets/NotificationSounds/delivered_sound.mp3";
import {toast} from "react-toastify";

import ImportExportIcon from '@mui/icons-material/ImportExport';
import NoOrder from "../../molecul/NoOrder";


const ORDER_SUBSCRIPTION = gql`
  subscription OnOrderAdded {
    orderCreated {
        message
    }
  }
`;

const ORDERS_QUERY = gql`
  query AllOrders {
    freshOrders {
        _id
        userId
        productIds
        productData{
            productId
            price
            quantity
            defaultPrice
            productImages
            state
            productTitle{
            en
            am
            ru
        }
            state
        }
        transactionId
        totalPrice
        userName
        paymentMethod
        tel
        email
        region
        address
        deliveryTime
        notes
        orderNumber
        orderState
    }
  }
`;

const MAKE_ORDER_DELIVERED = gql`
    mutation Mutation(
        $orderInput: OrderInput
    ) {
        makeOrderDelivered(
          orderInput: $orderInput
        ) {
            _id
        }
    }
`;

const OrdersSection = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [intervalID, setIntervalID] = useState(0);
    const [shouldIntervalBeCancelled, setShouldIntervalBeCancelled] = useState(false);

    const [productSorting, setProductSorting] = useState('newest');
    const [productData, setProductData] = useState([]);

    const navigate = useNavigate();

    const orderAudioPlayer = useRef(null);
    const reminderAudioPlayer = useRef(null);
    const deliveredAudioPlayer = useRef(null);
    const handleResize = () => {
        if (window.innerWidth < 800) {
            setIsMobile(true)
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        if(window.innerWidth < 800){
            setIsMobile(true)
        }
        window.addEventListener('resize', handleResize);

        return () =>{
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleChangeSortType = (sortCase, queryData) => {
        if (sortCase === 'added_date') {

            const arrayForSort = [...queryData]
            if (productSorting === 'default') {
                setProductData(arrayForSort);
            } else {
                setProductData(arrayForSort.reverse());
            }

            setProductData(arrayForSort.reverse());
        } else if (sortCase === 'delivery_time') {
            const arrayForSorting = [...productData];
            if (productSorting === 'default') {
                arrayForSorting.sort((a, b) => {
                    //The parse function will convert those strings to Dates.
                    const bdt = Date.parse(b.deliveryTime);
                    const adt = Date.parse(a.deliveryTime);
                    return bdt > adt ? 1 : (bdt < adt ? -1 : 0);
                });
                setProductData(arrayForSorting);
            } else {
                arrayForSorting.sort((a, b) => {
                    //The parse function will convert those strings to Dates.
                    const adt = Date.parse(b.deliveryTime);
                    const bdt = Date.parse(a.deliveryTime);
                    return bdt > adt ? 1 : (bdt < adt ? -1 : 0);
                });
                setProductData(arrayForSorting);
            }
        }
    }

    const playAudio = () => {
        if (orderAudioPlayer.current) {
            orderAudioPlayer.current.load(); // Reset the audio element
            orderAudioPlayer.current.addEventListener('canplaythrough', () => {
                orderAudioPlayer.current.play();
            });
        }
    }

    const {
        data: newData,
        loading: subscribeLoading,
    } = useSubscription(ORDER_SUBSCRIPTION);

    const {loading, error, data, refetch} = useQuery(ORDERS_QUERY);

    const [makeOrderDelivered] = useMutation(MAKE_ORDER_DELIVERED, {
            update(proxy, {data: {createNewOrder: orderData}}) {
                refetch();
            },
            onError({graphQLErrors}) {

                if (graphQLErrors && graphQLErrors[0].message) {
                    toast.error(graphQLErrors[0].message, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            }
        }
    );

    useEffect(() => {
        setShouldIntervalBeCancelled(false)
        if (!subscribeLoading) {
            playAudio();
            refetch();
            let myIntervalID = setInterval(myFunction, 10000);
            setIntervalID(myIntervalID);
        }

    }, [newData])

    useEffect(() => {
        refetch();

    }, [])


    useEffect(() => {
        if (!loading && data && data.freshOrders) {
            const arrayForSort = [...data.freshOrders]
            setProductData(arrayForSort.reverse());
        }

    }, [data, loading])


// For starting the interval ->
    useEffect(() => {
        clearInterval(intervalID);
        setShouldIntervalBeCancelled(false)
        let myIntervalID = setInterval(myFunction, 10000);
        setIntervalID(myIntervalID);
        return () => clearInterval(myIntervalID);
    }, [data]);

    useEffect(() => {
        if (shouldIntervalBeCancelled) {
            clearInterval(intervalID);     // this being inside a useEffect makes sure that it gets the fresh value of state
        }
    }, [shouldIntervalBeCancelled]);

// The function that makes the call
    const myFunction = () => {
        // make the call and get back the result
        if (!loading && data && data.freshOrders && !data.freshOrders.find(obj => obj.orderState === "new")) {
            // Here, I want to clear the interval, I just set shouldIntervalBeCancelled to be true
            setShouldIntervalBeCancelled(true);
            // Navigate the user to other screen after the interval is cleared
        } else if (!loading && data && data.freshOrders && !!data.freshOrders.find(obj => obj.orderState === "new")) {
            reminderAudioPlayer.current.play();
        }
    }
    if (loading) {
        return (
            <div> ... loading </div>
        );
    } else if (error) {
        return (
            <div> 😁 Error 😁 </div>
        );
    } else if (!loading && data && productData && productData.length === 0) {
        return (
            <>
                <div className={styles.orders_section}>
                    <h1 className={styles.section_heading}>
                        Orders
                    </h1>
                    <div className={styles.nav_btn_div}>

                        <button onClick={() => {
                            setProductSorting(productSorting === "newest" ? "default" : "newest");
                            handleChangeSortType('added_date', data.freshOrders);
                        }} className={styles.sort_btn}>
                            <span>Sort</span>
                            <ImportExportIcon fontSize={'large'}/>
                        </button>
                    </div>
                    <div className={styles.cart_item_table_div}>
                        <div className={styles.cart_item_heading_div}>
                            <div className={styles.cart_item_big_heading}>

                            </div>
                            <div className={styles.cart_item_small_heading}>
                                Order №
                            </div>
                            <div className={styles.cart_item_big_heading}>
                                Order Image
                            </div>
                            <div className={styles.cart_item_small_heading}>
                                User Name
                            </div>
                            <div className={styles.cart_item_small_heading}>
                                Price
                            </div>
                            <div className={styles.cart_item_big_heading + ' ' + styles.cursor_pointer} onClick={() => {
                                handleChangeSortType('delivery_time');
                                setProductSorting(productSorting === 'default' ? "newest" : "default");
                            }}>
                                Delivery Time
                                <ImportExportIcon fontSize={'medium'}/>
                            </div>

                        </div>
                        <div>
                            <NoOrder/>
                        </div>
                    </div>
                </div>

            </>
        )
    }
    if (isMobile) {
        return (
            <div className={styles.orders_section}>
                <h1 className={styles.section_heading}>
                    Orders
                </h1>
                <div className={styles.nav_btn_div}>

                    <button onClick={() => {
                        setProductSorting(productSorting === "newest" ? "default" : "newest");
                        handleChangeSortType('added_date', data.freshOrders);
                    }} className={styles.sort_btn}>
                        <span>Sort</span>
                        <ImportExportIcon fontSize={'large'}/>
                    </button>
                </div>
                <div className={styles.cart_item_table_div}>
                        {!loading && data && productData && productData.map((item, id) => {
                            return (
                                <div
                                    className={item.orderState === "new" ?
                                        styles.cart_item_div_mobile + " " + styles.new_cart_item_background
                                        :
                                        styles.cart_item_div_mobile
                                    }
                                    key={id}
                                    onClick={() => {
                                        setShouldIntervalBeCancelled(true);
                                        navigate("/order_details", {
                                            state: item,
                                        })
                                    }}
                                >
                                    <div className={styles.cart_item_small_mobile + " " + styles.cursor_pointer}>
                                        {item.orderNumber}
                                    </div>
                                    <div className={styles.cart_item_big_mobile}>
                                        {item.tel}
                                    </div>
                                    <div className={styles.cart_item_small_mobile}>
                                        {item.userName}
                                    </div>
                                    <div className={styles.cart_item_small_mobile}>
                                        {item.totalPrice}
                                    </div>

                                    <div className={styles.cart_item_big_mobile}>
                                        {item.deliveryTime}
                                    </div>
                                    <button className={styles.delivered_btn} onClick={() => {

                                        const productsDataCopy = JSON.parse(JSON.stringify(item.productData));
                                        let orderDataInput = {
                                            ...item,
                                            productData: [
                                                ...productsDataCopy,

                                            ]
                                        };
                                        delete orderDataInput['__typename'];
                                        delete orderDataInput['productTitle'];
                                        delete orderDataInput['orderState'];
                                        orderDataInput.productData.forEach(item => delete item['__typename']);
                                        makeOrderDelivered({
                                            variables: {
                                                orderInput: orderDataInput,
                                            }
                                        })
                                        deliveredAudioPlayer.current.play();
                                    }}>
                                        Delivered
                                    </button>
                                </div>
                            )
                        })}
                    <audio ref={orderAudioPlayer} src={NotificationSound}/>
                    <audio ref={reminderAudioPlayer} src={ReminderSound}/>
                    <audio ref={deliveredAudioPlayer} src={DeliveredSound}/>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.orders_section}>
            <h1 className={styles.section_heading}>
                Orders
            </h1>
            <div className={styles.nav_btn_div}>

                <button onClick={() => {
                    setProductSorting(productSorting === "newest" ? "default" : "newest");
                    handleChangeSortType('added_date', data.freshOrders);
                }} className={styles.sort_btn}>
                    <span>Sort</span>
                    <ImportExportIcon fontSize={'large'}/>
                </button>
            </div>
            <div className={styles.cart_item_table_div}>
                <div className={styles.cart_item_heading_div}>
                    <div className={styles.cart_item_big_heading}>

                    </div>
                    <div className={styles.cart_item_small_heading}>
                        Order №
                    </div>
                    <div className={styles.cart_item_big_heading}>
                        Order Image
                    </div>
                    <div className={styles.cart_item_small_heading}>
                        User Name
                    </div>
                    <div className={styles.cart_item_small_heading}>
                        Price
                    </div>
                    <div className={styles.cart_item_big_heading + ' ' + styles.cursor_pointer} onClick={() => {
                        handleChangeSortType('delivery_time');
                        setProductSorting(productSorting === 'default' ? "newest" : "default");
                    }}>
                        Delivery Time
                        <ImportExportIcon fontSize={'medium'}/>
                    </div>

                </div>

                <div>
                    {!loading && data && productData && productData.map((item, id) => {
                        return (
                            <div
                                className={item.orderState === "new" ?
                                    styles.cart_item_div + " " + styles.new_cart_item_background
                                    :
                                    styles.cart_item_div
                                }
                                key={id}
                            >
                                <div className={styles.cart_item_big}>
                                    <button className={styles.delivered_btn} onClick={async () => {

                                        const productsDataCopy = JSON.parse(JSON.stringify(item.productData));
                                        let orderDataInput = {
                                            ...item,
                                            productData: [
                                                ...productsDataCopy,
                                            ]
                                        };
                                        delete orderDataInput['__typename'];
                                        delete orderDataInput['productTitle'];
                                        delete orderDataInput['orderState'];
                                        orderDataInput.productData.forEach(item => delete item['__typename']);
                                        await makeOrderDelivered({
                                            variables: {
                                                orderInput: orderDataInput,
                                            }
                                        })
                                        deliveredAudioPlayer.current.play();
                                    }}>
                                        Delivered
                                    </button>
                                </div>
                                <div className={styles.cart_item_small + " " + styles.cursor_pointer} onClick={() => {
                                    setShouldIntervalBeCancelled(true);
                                    navigate("/order_details", {
                                        state: item,
                                    })
                                }}>
                                    {item.orderNumber}
                                </div>
                                <div className={styles.cart_item_big}>
                                    {item.tel}
                                </div>
                                <div className={styles.cart_item_small}>
                                    {item.userName}
                                </div>
                                <div className={styles.cart_item_small}>
                                    {item.totalPrice}
                                </div>

                                <div className={styles.cart_item_big}>
                                    {item.deliveryTime}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <audio ref={orderAudioPlayer} src={NotificationSound}/>
                <audio ref={reminderAudioPlayer} src={ReminderSound}/>
                <audio ref={deliveredAudioPlayer} src={DeliveredSound}/>
            </div>
        </div>
    );
};

export default OrdersSection;