import OrdersHistorySection from "../../component/organism/OrdersHistory";

const OrdersHistory = () => {
    return(
        <div>
            <OrdersHistorySection/>
        </div>
    );
};

export default OrdersHistory;