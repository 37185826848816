import {useEffect, useRef, useState} from "react";

import styles from "./style.module.scss";

import stateJson from "../../../assets/productState/default.json";
import categoryJson from "../../../assets/productCategory/default.json";
import typeJson from "../../../assets/productType/default.json";

import removeIcon from '../../../assets/icons/commonIcons/remove.png';

import Grid from "@mui/material/Unstable_Grid2";
import {InputField, TagInput} from "../../atom/Input";
import {useForm} from "../../../hooks/useForm";

import OptionalDropdown from "../../atom/OptionalDropdownField";
import CustomBreadcrumbs from "../../atom/CustomBreadcrumbs/";

import {useCreateNewProduct} from '../../../hooks/products/useCreateNewProduct';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import StateOptionalDropdown from "../../atom/OptionalDropdownField/StateOptionalField";

const AddNewItemSection = () => {
    const productCategory = categoryJson.category;
    const productType = typeJson.types;
    const productState = stateJson.states;
    const saleTypes = stateJson.saleTypes;
    const formRef = useRef(null);

    const navigate = useNavigate();

    const [selectedRawImage, setSelectedRawImage] = useState('');
    const [selectedCookedImage, setSelectedCookedImage] = useState('');
    const [selectedCookedSmallImage, setSelectedCookedSmallImage] = useState('');
    const [selectedRawSmallImage, setSelectedRawSmallImage] = useState('');

    const fileInputRef = useRef(null);

    const handleSubmitForm = () => {
        createNewProduct();
    };

    const {changeHandler, onSubmit, setInputValue, values} = useForm(handleSubmitForm, {
        status: 'active',
        productTitle: {
            en: '',
            ru: '',
            am: '',
        },
        description: {
            en: '',
            ru: '',
            am: '',
        },
        price: '',
        preparationPrice: '500',
        productRawImage: '',
        productCookedImage: '',
        productSmallRawImage: '',
        productSmallCookedImage: '',
        state: [],
        category: '',
        type: '',
        saleType: 'kg',
        searchTags: []
    });


    const {createNewProduct} = useCreateNewProduct({values})

// This function will be triggered when the file field changes
    const handleImageChange = (event, index) => {
        if (event && event.target.files && event.target.files.length > 0) {
            if (event && event.target.files[0].size < 400000) {
                for (let i = 0; i < event.target.files.length; i++) {
                    const reader = new FileReader();
                    reader.readAsDataURL(event.target.files[i]);
                    reader.onload = () => {
                        const result = reader.result;
                        switch (index) {
                            case "raw":
                                return setSelectedRawImage(result);
                            case "cooked":
                                return setSelectedCookedImage(result);
                            case "cookedSmall":
                                return setSelectedCookedSmallImage(result);
                            case "rawSmall":
                                return setSelectedRawSmallImage(result);
                        }
                    };
                }
            } else {
                toast.error("File is too big!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        } else {
            switch (index) {
                case "raw":
                    return setSelectedRawImage('');
                case "cooked":
                    return setSelectedCookedImage('');
                case "cookedSmall":
                    return setSelectedCookedSmallImage('');
                case "rawSmall":
                    return setSelectedRawSmallImage('');
            }
        }
    };


    useEffect(() => {
        setInputValue('productRawImage', selectedRawImage);
    }, [selectedRawImage]);
    useEffect(() => {
        setInputValue('productCookedImage', selectedCookedImage);
    }, [selectedCookedImage]);
    useEffect(() => {
        setInputValue('productSmallRawImage', selectedRawSmallImage);
    }, [selectedRawSmallImage]);
    useEffect(() => {
        setInputValue('productSmallCookedImage', selectedCookedSmallImage);
    }, [selectedCookedSmallImage]);
    const handleSubmit = () => {
        const checkObj = {
            ...values
        };
        delete checkObj['productRawImage'];
        delete checkObj['sliderVisibility'];
        delete checkObj['productCookedImage'];
        delete checkObj['productSmallCookedImage'];
        delete checkObj['productSmallRawImage'];
        
        const containsFalsy = Object.values(checkObj).some(item => !item || (Array.isArray(item) && item.length === 0));
        
        if (containsFalsy) {
            console.log(values)
            toast.error("Please fill out all fields !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } else {
            onSubmit();
            navigate("/");
        }
    }
    return (
        <form ref={formRef} className={styles.mainSection} onKeyDown={(event) => {
            event.preventDefault();
        }} onSubmit={(e) => {
            e.preventDefault();
        }}>
            <CustomBreadcrumbs/>
            <div className={styles.centeredFlexDiv} onKeyDown={(event) => {
                event.stopPropagation();
            }}>
                <Grid
                    container
                    direction="column"
                    spacing={{mobile: 3, tablet: 3, laptop: 3, desktop: 3}}
                    alignItems="start"
                    style={{
                        width: "100%",
                        margin: '0'
                    }}
                >
                    <div className={styles.product_title_div}>
                        <label htmlFor="productTitle" className={styles.label}>Product title</label>
                        <div className={styles.inputField}>
                            am
                            <InputField
                                type='text'
                                name="productTitle"
                                bgColor={"#FFFFFF"}
                                borderColor={"#D1D2D5"}
                                onChange={(e) => {
                                    setInputValue("productTitle", {
                                        ...values.productTitle,
                                        am: e.target.value,
                                    })
                                }}
                            />
                            en
                            <InputField
                                type='text'
                                name="productTitle"
                                bgColor={"#FFFFFF"}
                                borderColor={"#D1D2D5"}
                                onChange={(e) => {
                                    setInputValue("productTitle", {
                                        ...values.productTitle,
                                        en: e.target.value,
                                    })
                                }}
                            />
                            ru
                            <InputField
                                type='text'
                                name="productTitle"
                                bgColor={"#FFFFFF"}
                                borderColor={"#D1D2D5"}
                                onChange={(e) => {
                                    setInputValue("productTitle", {
                                        ...values.productTitle,
                                        ru: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.flexDiv}>
                            <label htmlFor="description" className={styles.label}>Description</label>

                            <div className={styles.product_description_div}>
                                <span>am</span>
                                <textarea
                                    name="description"
                                    onChange={(e) => {
                                        setInputValue("description", {
                                            ...values.description,
                                            am: e.target.value,
                                        })
                                    }}
                                    className={styles.textArea}/>


                                <span>en</span>
                                <textarea
                                    name="description"
                                    onChange={(e) => {
                                        setInputValue("description", {
                                            ...values.description,
                                            en: e.target.value,
                                        })
                                    }}
                                    className={styles.textArea}/>

                                <span>ru</span>
                                <textarea
                                    name="description"
                                    onChange={(e) => {
                                        setInputValue("description", {
                                            ...values.description,
                                            ru: e.target.value,
                                        })
                                    }}
                                    className={styles.textArea}/>
                            </div>
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={4} desktop={4}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.horizontalDiv}>
                            <StateOptionalDropdown
                                options={productState}
                                label={"Product State"}
                                setInputStateValue={(value) => {
                                    setInputValue('state', value);
                                }}
                            />
                        </div>
                        <div className={styles.horizontalDiv}>
                            <OptionalDropdown
                                options={saleTypes}
                                label={"Sale Type"}
                                value={values.saleType}
                                setInputStateValue={(value) => {
                                    setInputValue('saleType', value);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <label htmlFor="price" className={styles.label}>Price</label>
                        <div className={styles.inputField}>
                            <InputField
                                type='Price'
                                name="price"
                                bgColor={"#FFFFFF"}
                                borderColor={"#D1D2D5"}
                                onChange={changeHandler}
                            />
                        </div>
                    </Grid>

                    {
                        values.state.includes('cooked') &&
                        <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                              sx={{
                                  paddingLeft: 0,
                                  paddingRight: 0,
                              }}>
                            <label htmlFor="price" className={styles.label}>Preparation Price</label>
                            <div className={styles.inputField}>
                                <InputField
                                    type='Price'
                                    name="preparationPrice"
                                    bgColor={"#FFFFFF"}
                                    borderColor={"#D1D2D5"}
                                    onChange={changeHandler}
                                    defaultValue={500}
                                />
                            </div>
                        </Grid>
                    }
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <label htmlFor="companyName" className={styles.label}>Product BIG Raw image</label>
                        <div className={styles.inputField}>
                            <input
                                type='file'
                                onChange={(event) => {
                                    handleImageChange(event, "raw")
                                }}
                                ref={fileInputRef}
                                className={styles.fileInput}
                                accept="image/webp"
                                multiple
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        {selectedRawImage && (
                            <Grid item mobile={2} tablet={2} laptop={2} desktop={2}>
                                <div className={styles.imgPreviewDiv}>
                                    <img src={selectedRawImage} alt="uploaded img" className={styles.imgPreview}/>
                                    <button
                                        type="button"
                                        className={styles.removeImgButton}
                                        onClick={() => handleImageChange(null, "raw")}
                                    >
                                        <img src={removeIcon} alt={"remove"}/>
                                    </button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <label htmlFor="companyName" className={styles.label}>Product SMALL Raw image</label>
                        <div className={styles.inputField}>
                            <input
                                type='file'
                                onChange={(event) => {
                                    handleImageChange(event, "rawSmall")
                                }}
                                ref={fileInputRef}
                                className={styles.fileInput}
                                accept="image/webp"
                                multiple
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        {selectedRawSmallImage && (
                            <Grid item mobile={2} tablet={2} laptop={2} desktop={2}>
                                <div className={styles.imgPreviewDiv}>
                                    <img src={selectedRawSmallImage} alt="uploaded img" className={styles.imgPreview}/>
                                    <button
                                        type="button"
                                        className={styles.removeImgButton}
                                        onClick={() => handleImageChange(null, "rawSmall")}
                                    >
                                        <img src={removeIcon} alt={"remove"}/>
                                    </button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    {
                        values.state.includes('cooked') &&
                        <>
                            <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                                  sx={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                  }}>
                                <label htmlFor="companyName" className={styles.label}>Product BIG Cooked image</label>
                                <div className={styles.inputField}>
                                    <input
                                        type='file'
                                        onChange={(event) => {
                                            handleImageChange(event, "cooked")
                                        }}
                                        ref={fileInputRef}
                                        className={styles.fileInput}
                                        accept="image/webp"
                                        multiple
                                    />
                                </div>
                            </Grid>
                            <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                                  sx={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                  }}>
                                {selectedCookedImage &&
                                    <Grid item mobile={2} tablet={2} laptop={2} desktop={2}>
                                        <div className={styles.imgPreviewDiv}>
                                            <img src={selectedCookedImage} alt="uploaded img"
                                                 className={styles.imgPreview}/>
                                            <button type="button" className={styles.removeImgButton}
                                                    onClick={() => handleImageChange(null, "cooked")}>
                                                <img src={removeIcon} alt={"remove"}/>
                                            </button>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                                  sx={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                  }}>
                                <label htmlFor="companyName" className={styles.label}>Product SMALL Cooked image</label>
                                <div className={styles.inputField}>
                                    <input
                                        type='file'
                                        onChange={(event) => {
                                            handleImageChange(event, "cookedSmall")
                                        }}
                                        ref={fileInputRef}
                                        className={styles.fileInput}
                                        accept="image/webp"
                                        multiple
                                    />
                                </div>
                            </Grid>
                            <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                                  sx={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                  }}>
                                {selectedCookedSmallImage &&
                                    <Grid item mobile={2} tablet={2} laptop={2} desktop={2}>
                                        <div className={styles.imgPreviewDiv}>
                                            <img src={selectedCookedSmallImage} alt="uploaded img"
                                                 className={styles.imgPreview}/>
                                            <button type="button" className={styles.removeImgButton}
                                                    onClick={() => handleImageChange(null, "cookedSmall")}>
                                                <img src={removeIcon} alt={"remove"}/>
                                            </button>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </>
                    }


                    <Grid item mobile={12} tablet={12} laptop={4} desktop={4}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.horizontalDiv}>
                            <OptionalDropdown
                                options={productType}
                                label={"Product Type"}
                                setInputStateValue={(value) => {
                                    setInputValue('type', value);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={4} desktop={4}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.horizontalDiv}>
                            <OptionalDropdown
                                options={productCategory}
                                label={"Product Category"}
                                setInputStateValue={(value) => {
                                    setInputValue('category', value);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={4} desktop={4}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.columnDiv}>
                            <label htmlFor="searchTags" className={styles.label}>Tags</label>
                            <p className={styles.tagsParagraph}>(we will limit them with 15 tags)</p>
                            <TagInput
                                className={styles.tagInput}
                                placeholder={"Type and press enter and the tag will be saved"}
                                setInputStateValue={(value) => {
                                    setInputValue("searchTags", value)
                                }}/>
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={10} desktop={10}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>

                    </Grid>
                </Grid>
                <div className={styles.btnDiv}>
                    <button type="button" className={styles.cancelBtn} onClick={() => {
                        navigate("/");
                    }}>
                        Cancel
                    </button>
                    <div className={styles.submitBtnDiv}>
                        <button type="button" className={styles.saveBtn} onClick={handleSubmit}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AddNewItemSection;