import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {toast} from "react-toastify";

const CREATE_NEW_PRODUCT = gql`
    mutation Mutation(
        $productInput: ProductInput
    ) {
        createNewProduct(
            productInput: $productInput
        ) {
            productId
        }
    }
`;

export const useCreateNewProduct = ({ values }) => {
    const [createNewProduct, {data, loading, error}] = useMutation(CREATE_NEW_PRODUCT, {
        update(proxy){
            toast.success("Success! Your product has been added", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                toast.error(graphQLErrors[0].message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            } else {
                console.error('An unknown error occurred:', graphQLErrors);
            }
        },
        variables: { productInput:  values}
    });
    return {
        data,
        createNewProduct,
        loading,
        error
    };
};

