import AdminPanel from "../../component/organism/AdminPanel";

const AdminPage = () => {

    return (
        <div>
            <AdminPanel />
        </div>
    );
};

export default AdminPage;