import "./assets/style.scss";

import 'react-toastify/dist/ReactToastify.css';
import {useRoutes} from "./routes";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {ToastContainer} from "react-toastify";
import {gql, useSubscription} from "@apollo/client";

import ReminderSound from "./assets/NotificationSounds/reminder_sound.mp3";
import {useEffect, useMemo, useRef} from "react";

const ORDER_SUBSCRIPTION = gql`
  subscription OnOrderAdded {
    orderCreated {
        message
    }
  }
`;

function App() {
  const routes = useRoutes();
    const reminderAudioPlayer = useRef(null);

    const {
        data: newData,
        loading: subscribeLoading,
    } = useSubscription(ORDER_SUBSCRIPTION);

    const playAudio = () => {
        reminderAudioPlayer.current.play();
    }

    useMemo(() => {
        if (!subscribeLoading && newData) {
            playAudio();
        }
    }, [newData, subscribeLoading])


  return (
      <ThemeProvider
          theme={createTheme({
            breakpoints: {
              values: {
                  mobile: 420,
                  tablet: 768,
                  laptop: 1024,
                  desktop:1440
              },
            },
          })}
      >
          <div>
              {routes}
              <ToastContainer />
          </div>
          <audio ref={reminderAudioPlayer} src={ReminderSound}/>
      </ThemeProvider>
  );
}

export default App;
