import { loginSuccess } from '../../redux/features/user/userSlice';

import { useDispatch } from 'react-redux';

import { gql } from '@apollo/client';

import { useMutation } from '@apollo/client';

import {toast} from "react-toastify";

import { useNavigate } from 'react-router-dom';

// Define the GraphQL mutation for logging in

const LOGIN_ADMIN = gql`
    mutation Mutation(
        $adminLoginInput: AdminLoginInput
    ) {
        loginAdmin(
            adminLoginInput: $adminLoginInput
        ) {
            token 
        }
    }
`



// Define the Redux Toolkit async thunk for logging in
export const useLoginUser= ({values}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
      // Use the Apollo Client to send a login mutation to the server
    const [loginAdmin, {loading}] = useMutation(LOGIN_ADMIN, {
      update(proxy, { data: { loginAdmin: userData }}) {
        const token = userData.token;
        dispatch(loginSuccess(token));
        // Store the token in local storage
        localStorage.setItem('token', token);
          navigate("/");
      },
      onError({graphQLErrors}){
        if(graphQLErrors && graphQLErrors[0].message){
          toast.error(graphQLErrors[0].message, {
            position: toast.POSITION.BOTTOM_CENTER
        });
        }
      },
      variables: { adminLoginInput: values}
  })
      
    return {
      loginAdmin,
      loading
    };
};
