import styles from "./style.module.scss";

import penIcon from "../../../assets/icons/commonIcons/pen.png";

import {useEffect, useState} from "react";
import {gql} from "graphql-tag";
import {useQuery} from "@apollo/client";
import StatusSwitch from "../../molecul/StatusSwitchPopup";
import useRemoveProduct from "../../../hooks/products/useRemoveProduct";
import {Link, useNavigate} from "react-router-dom";

const GET_ALL_PRODUCTS = gql`
  query {
   products{
        _id
        status
        productTitle{
            en
            am
            ru
        }
        description{
            en
            am
            ru
        }
        specialOffer
        price
        preparationPrice
        productImages{
            productRawImage
            productCookedImage
            productSmallRawImage
            productSmallCookedImage
        }
        category
        type
        searchTags
        state
        saleType
        sliderVisibility
    }
  }
`;

const Products = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isTollBarOpen, setIsTollBarOpen] = useState(false);
    const [selectedProductArray, setSelectedProductArray] = useState([]);

    const navigate = useNavigate();

    const {loading, error, data, refetch} = useQuery(GET_ALL_PRODUCTS);

    useEffect(() => {
        refetch();
    }, [refetch]);


    const handleResetSelectedArray = () => {
        setSelectedProductArray([]);
    };
    const {removeProduct} = useRemoveProduct(selectedProductArray, refetch, handleResetSelectedArray);

    useEffect(() => {
        if (selectedProductArray.length === 0) {
            setIsTollBarOpen(false);
        } else {
            setIsTollBarOpen(true);
        }
        ;
    }, [selectedProductArray]);
    const handleChangeStatus = () => {
        setIsPopupOpen(true);
    }
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (

        <div className={styles.mainDiv}>
            <div className={styles.flexDiv }>
                <Link to={'/'} className={styles.home_link}>
                    back
                </Link>
                <button className={styles.selectAllBtn} onClick={() => {
                    if (selectedProductArray.length !== data.products.length) {
                        setSelectedProductArray(data.products);
                    } else {
                        setSelectedProductArray([]);
                    }

                }}>Select All
                </button>
                {isTollBarOpen && (
                    <div className={styles.toolBar}>
                        {selectedProductArray.length === 1 &&
                            <button className={styles.editBtn}
                                    onClick={() => {
                                        navigate('/edit_item', {
                                            state: {
                                                productData: selectedProductArray[0],
                                            }
                                        })
                                    }
                            }>
                                <img src={penIcon} alt={"penIcon"}/>
                                Edit
                            </button>}
                        <button className={styles.changeStatusBtn} onClick={() => {
                            handleChangeStatus();
                        }}>Change Status
                        </button>
                        <button className={styles.removeBtn} onClick={() => {
                            removeProduct();
                        }}>Remove
                        </button>
                    </div>
                )}
            </div>
            <div className={styles.productPreviewHeading}>
                <div className={styles.firstHeadingColumn}>
                    <p> Product image </p>
                </div>
                <div className={styles.productColumn}>
                    <p> Product type </p>
                </div>
                <div className={styles.productColumn}>
                    <p> Product name </p>
                </div>
                <div className={styles.productColumn}>
                    <p> Price </p>
                </div>
                <div className={styles.productColumn}>
                    <p> Category </p>
                </div>
                <div className={styles.lastProductColumn}>
                    <p> Status </p>
                </div>
            </div>

            <div>
                {data.products.map((product, id) => {
                        const isSelected = selectedProductArray.includes(product);
                        return (
                            <div key={id}
                                 className={isSelected ? styles.productPreviewDiv + " " + styles.activeProductDiv : styles.productPreviewDiv}
                                 onClick={() => {
                                     if (isSelected) {
                                         setSelectedProductArray(selectedProductArray.filter((chosenSize) => chosenSize !== product));
                                     } else {
                                         setSelectedProductArray([...selectedProductArray, product]);
                                     }


                                 }}>
                                <div className={styles.firstProductColumn}>
                                    <img src={product.productImages.productRawImage ? product.productImages.productRawImage : product.productImages.productCookedImage} alt={"productIMG"} className={styles.productImg}/>
                                </div>
                                <div className={styles.productColumn}>
                                    <span> {product.type} </span>
                                </div>
                                <div className={styles.productColumn}>
                                    <p> {product.productTitle.en} </p>
                                </div>
                                <div className={styles.productColumn}>
                                    <p> {product.price + "AMD"} </p>
                                </div>
                                <div className={styles.productColumn}>
                                    <p> {product.category} </p>
                                </div>
                                <div className={styles.lastProductColumn}>
                                    <div
                                        className={product.status === 'active' ? styles.activeProduct : styles.inactiveProduct}
                                    >{product.status}</div>
                                </div>
                            </div>
                        )
                    }
                )}
            </div>
            {isPopupOpen ?
                <StatusSwitch
                    closePopup={() => {
                        setIsPopupOpen(false);
                    }}
                    refetchData={() => {
                        refetch();
                    }}
                    resetSelectedProduct={handleResetSelectedArray}
                    productArray={selectedProductArray}
                /> : null}
        </div>
    )
}

export default Products;