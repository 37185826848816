import {useEffect, useRef, useState} from "react";

import styles from "./style.module.scss";

import stateJson from "../../../assets/productState/default.json";
import categoryJson from "../../../assets/productCategory/default.json";
import typeJson from "../../../assets/productType/default.json";

import removeIcon from '../../../assets/icons/commonIcons/remove.png';

import Grid from "@mui/material/Unstable_Grid2";
import {InputField, TagInput} from "../../atom/Input";
import {useForm} from "../../../hooks/useForm";

import OptionalDropdown from "../../atom/OptionalDropdownField";
import CustomBreadcrumbs from "../../atom/CustomBreadcrumbs";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import useUpdateProduct from "../../../hooks/products/useUpdateProduct";
import StateOptionalDropdown from "../../atom/OptionalDropdownField/StateOptionalField";


const EditItemSection = () => {
    const {state} = useLocation();
    const data = state.productData;


    const productState = stateJson.states;
    const productCategory = categoryJson.category;
    const productType = typeJson.types;
    const saleTypes = stateJson.saleTypes;

    const [queryData, setQueryData] = useState(data);
    console.log(data.specialOffer)
    const [isSpecialOffersChecked, setIsSpecialOffersChecked] = useState(!!data.specialOffer);

    const [selectedRawImage, setSelectedRawImage] = useState(data.productImages.productRawImage);
    const [selectedCookedImage, setSelectedCookedImage] = useState(data.productImages.productCookedImage);
    const [selectedCookedSmallImage, setSelectedCookedSmallImage] = useState(data.productImages.productSmallCookedImage);
    const [selectedRawSmallImage, setSelectedRawSmallImage] = useState(data.productImages.productSmallRawImage);

    const navigate = useNavigate();
    const formRef = useRef(null);
    const fileInputRef = useRef(null);


    const handleSubmitForm = () => {
        updateProductData();
    };

    const {changeHandler, onSubmit, setInputValue, values} = useForm(handleSubmitForm, {
        _id: data._id,
        specialOffer: '',
        productTitle: {
            en: data.productTitle.en,
            ru: data.productTitle.ru,
            am: data.productTitle.am,
        },
        description: {
            en: data.description.en,
            ru: data.description.ru,
            am: data.description.am,
        },
        price: '',
        preparationPrice: '',
        productRawImage: data.productImages.productRawImage,
        productCookedImage: data.productImages.productCookedImage,
        productSmallRawImage: data.productImages.productSmallRawImage,
        productSmallCookedImage: data.productImages.productSmallCookedImage,
        state: data.state,
        category: '',
        type: "",
        saleType: data.saleType,
        searchTags: [],
        sliderVisibility: data.sliderVisibility
    });


    const {updateProductData} = useUpdateProduct(values);


    const handleImageChange = (event, index) => {
        if (event && event.target.files && event.target.files.length > 0) {
            if (event && event.target.files[0].size < 400000) {
                for (let i = 0; i < event.target.files.length; i++) {
                    const reader = new FileReader();
                    reader.readAsDataURL(event.target.files[i]);
                    reader.onload = () => {
                        const result = reader.result;
                        switch (index) {
                            case "raw":
                                return setSelectedRawImage(result);
                            case "cooked":
                                return setSelectedCookedImage(result);
                            case "cookedSmall":
                                return setSelectedCookedSmallImage(result);
                            case "rawSmall":
                                return setSelectedRawSmallImage(result);
                        }
                    };
                }
            } else {
                toast.error("File is too big!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        } else {
            switch (index) {
                case "raw":
                    return setSelectedRawImage('');
                case "cooked":
                    return setSelectedCookedImage('');
                case "cookedSmall":
                    return setSelectedCookedSmallImage('');
                case "rawSmall":
                    return setSelectedRawSmallImage('');
            }
        }
    };


    useEffect(() => {
        setInputValue('productRawImage', selectedRawImage);
    }, [selectedRawImage]);
    useEffect(() => {
        setInputValue('productCookedImage', selectedCookedImage);
    }, [selectedCookedImage]);
    useEffect(() => {
        setInputValue('productSmallRawImage', selectedRawSmallImage);
    }, [selectedRawSmallImage]);
    useEffect(() => {
        setInputValue('productSmallCookedImage', selectedCookedSmallImage);
    }, [selectedCookedSmallImage]);

    return (
        <form ref={formRef} className={styles.mainSection} onKeyDown={(event) => {
            event.preventDefault();
        }} onSubmit={(e) => {
            e.preventDefault();
        }}>
            <CustomBreadcrumbs/>
            <div className={styles.centeredFlexDiv} onKeyDown={(event) => {
                event.stopPropagation();
            }}>
                <Grid
                    container
                    direction="column"
                    spacing={{mobile: 3, tablet: 3, laptop: 3, desktop: 3}}
                    alignItems="start"
                    style={{
                        width: "100%",
                        margin: '0'
                    }}
                >
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div
                            className={styles.special_orders_div}
                        >
                            <div onClick={() => {
                                setInputValue("specialOffer", '0%')
                                setIsSpecialOffersChecked(!isSpecialOffersChecked);
                            }}>
                                <label>Special Offers</label>
                                <input type={"checkbox"} checked={isSpecialOffersChecked} readOnly={true}/>
                            </div>

                            {
                                isSpecialOffersChecked &&
                                <div>
                                    <label htmlFor="specialOffer" className={styles.label}>Special Offer</label>
                                    <div className={styles.inputField}>
                                        <InputField
                                            type='text'
                                            name="specialOffer"
                                            bgColor={"#FFFFFF"}
                                            borderColor={"#D1D2D5"}
                                            onChange={changeHandler}
                                            defaultValue={queryData.specialOffer ? queryData.specialOffer : '0%'}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </Grid>
                    <div className={styles.product_title_div}>
                        <label htmlFor="productTitle" className={styles.label}>Product title</label>
                        <div className={styles.inputField}>
                            am
                            <InputField
                                type='text'
                                name="productTitle"
                                bgColor={"#FFFFFF"}
                                defaultValue={queryData.productTitle.am ? queryData.productTitle.am : ''}
                                borderColor={"#D1D2D5"}
                                onChange={(e) => {
                                    setInputValue("productTitle", {
                                        ...values.productTitle,
                                        am: e.target.value,
                                    })
                                }}
                            />
                            en
                            <InputField
                                type='text'
                                name="productTitle"
                                bgColor={"#FFFFFF"}
                                borderColor={"#D1D2D5"}
                                defaultValue={queryData.productTitle.en ? queryData.productTitle.en : ''}
                                onChange={(e) => {
                                    setInputValue("productTitle", {
                                        ...values.productTitle,
                                        en: e.target.value,
                                    })
                                }}
                            />
                            ru
                            <InputField
                                type='text'
                                name="productTitle"
                                bgColor={"#FFFFFF"}
                                borderColor={"#D1D2D5"}
                                defaultValue={queryData.productTitle.ru ? queryData.productTitle.ru : ''}
                                onChange={(e) => {
                                    setInputValue("productTitle", {
                                        ...values.productTitle,
                                        ru: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.flexDiv}>
                            <label htmlFor="description" className={styles.label}>Description</label>

                            <div className={styles.product_description_div}>
                                <span>am</span>
                                <textarea
                                    name="description"
                                    defaultValue={queryData.description.am ? queryData.description.am : ''}
                                    onChange={(e) => {
                                        setInputValue("description", {
                                            ...values.description,
                                            am: e.target.value,
                                        })
                                    }}
                                    className={styles.textArea}/>


                                <span>en</span>
                                <textarea
                                    name="description"
                                    defaultValue={queryData.description.en ? queryData.description.en : ''}
                                    onChange={(e) => {
                                        setInputValue("description", {
                                            ...values.description,
                                            en: e.target.value,
                                        })
                                    }}
                                    className={styles.textArea}/>

                                <span>ru</span>
                                <textarea
                                    name="description"
                                    defaultValue={queryData.description.ru ? queryData.description.ru : ''}
                                    onChange={(e) => {
                                        setInputValue("description", {
                                            ...values.description,
                                            ru: e.target.value,
                                        })
                                    }}
                                    className={styles.textArea}/>
                            </div>
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={4} desktop={4}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.horizontalDiv}>
                            <StateOptionalDropdown
                                options={productState}
                                label={"Product State"}
                                value={queryData.state ? queryData.state : []}
                                setInputStateValue={(value) => {
                                    setInputValue('state', value);
                                }}
                            />
                        </div>
                        <div className={styles.horizontalDiv}>
                            <OptionalDropdown
                                options={saleTypes}
                                label={"Sale Type"}
                                value={values.saleType}
                                setInputStateValue={(value) => {
                                    setInputValue('saleType', value);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <label htmlFor="price" className={styles.label}>Price</label>
                        <div className={styles.inputField}>
                            <InputField
                                type='Price'
                                name="price"
                                bgColor={"#FFFFFF"}
                                borderColor={"#D1D2D5"}
                                onChange={changeHandler}
                                defaultValue={queryData.price ? queryData.price : ''}
                            />
                        </div>
                    </Grid>
                    {values.state.includes('cooked') &&
                        <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                              sx={{
                                  paddingLeft: 0,
                                  paddingRight: 0,
                              }}>
                            <label htmlFor="price" className={styles.label}>Preparation Price</label>
                            <div className={styles.inputField}>
                                <InputField
                                    type='Price'
                                    name="preparationPrice"
                                    bgColor={"#FFFFFF"}
                                    borderColor={"#D1D2D5"}
                                    onChange={changeHandler}
                                    defaultValue={queryData.preparationPrice ? queryData.preparationPrice : ''}
                                />
                            </div>
                        </Grid>
                    }
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <label htmlFor="companyName" className={styles.label}>Product BIG Raw image</label>
                        <div className={styles.inputField}>
                            <input
                                type='file'
                                onChange={(event) => {
                                    handleImageChange(event, "raw")
                                }}
                                ref={fileInputRef}
                                className={styles.fileInput}
                                accept="image/webp"
                                multiple
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        {selectedRawImage && (
                            <Grid item mobile={2} tablet={2} laptop={2} desktop={2}>
                                <div className={styles.imgPreviewDiv}>
                                    <img src={selectedRawImage} alt="uploaded img" className={styles.imgPreview}/>
                                    <button
                                        type="button"
                                        className={styles.removeImgButton}
                                        onClick={() => handleImageChange(null, "raw")}
                                    >
                                        <img src={removeIcon} alt={"remove"}/>
                                    </button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <label htmlFor="companyName" className={styles.label}>Product SMALL Raw image</label>
                        <div className={styles.inputField}>
                            <input
                                type='file'
                                onChange={(event) => {
                                    handleImageChange(event, "rawSmall")
                                }}
                                ref={fileInputRef}
                                className={styles.fileInput}
                                accept="image/webp"
                                multiple
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        {selectedRawSmallImage && (
                            <Grid item mobile={2} tablet={2} laptop={2} desktop={2}>
                                <div className={styles.imgPreviewDiv}>
                                    <img src={selectedRawSmallImage} alt="uploaded img" className={styles.imgPreview}/>
                                    <button
                                        type="button"
                                        className={styles.removeImgButton}
                                        onClick={() => handleImageChange(null, "rawSmall")}
                                    >
                                        <img src={removeIcon} alt={"remove"}/>
                                    </button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    {
                        values.state.includes('cooked') &&
                        <>
                            <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                                  sx={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                  }}>
                                <label htmlFor="companyName" className={styles.label}>Product BIG Cooked image</label>
                                <div className={styles.inputField}>
                                    <input
                                        type='file'
                                        onChange={(event) => {
                                            handleImageChange(event, "cooked")
                                        }}
                                        ref={fileInputRef}
                                        className={styles.fileInput}
                                        accept="image/webp"
                                        multiple
                                    />
                                </div>
                            </Grid>
                            <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                                  sx={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                  }}>
                                {selectedCookedImage &&
                                    <Grid item mobile={2} tablet={2} laptop={2} desktop={2}>
                                        <div className={styles.imgPreviewDiv}>
                                            <img src={selectedCookedImage} alt="uploaded img"
                                                 className={styles.imgPreview}/>
                                            <button type="button" className={styles.removeImgButton}
                                                    onClick={() => handleImageChange(null, "cooked")}>
                                                <img src={removeIcon} alt={"remove"}/>
                                            </button>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                                  sx={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                  }}>
                                <label htmlFor="companyName" className={styles.label}>Product SMALL Cooked image</label>
                                <div className={styles.inputField}>
                                    <input
                                        type='file'
                                        onChange={(event) => {
                                            handleImageChange(event, "cookedSmall")
                                        }}
                                        ref={fileInputRef}
                                        className={styles.fileInput}
                                        accept="image/webp"
                                        multiple
                                    />
                                </div>
                            </Grid>
                            <Grid item mobile={12} tablet={12} laptop={12} desktop={12}
                                  sx={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                  }}>
                                {selectedCookedSmallImage &&
                                    <Grid item mobile={2} tablet={2} laptop={2} desktop={2}>
                                        <div className={styles.imgPreviewDiv}>
                                            <img src={selectedCookedSmallImage} alt="uploaded img"
                                                 className={styles.imgPreview}/>
                                            <button type="button" className={styles.removeImgButton}
                                                    onClick={() => handleImageChange(null, "cookedSmall")}>
                                                <img src={removeIcon} alt={"remove"}/>
                                            </button>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </>
                    }
                    <div
                        className={styles.slider_visibility_checkbox}
                        onClick={() => {
                            setInputValue("sliderVisibility", !values.sliderVisibility)
                        }}>
                        <label className={styles.label}>Slider Visibility</label>
                        <input type={"checkbox"} checked={values.sliderVisibility} readOnly={true}/>
                    </div>
                    <Grid item mobile={12} tablet={12} laptop={4} desktop={4}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.horizontalDiv}>
                            <OptionalDropdown
                                options={productType}
                                label={"Product Type"}
                                value={queryData.type ? queryData.type : ''}
                                setInputStateValue={(value) => {
                                    setInputValue('type', value);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={4} desktop={4}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.horizontalDiv}>

                            <OptionalDropdown
                                options={productCategory}
                                label={"Product Category"}
                                value={queryData.category ? queryData.category : ''}
                                setInputStateValue={(value) => {
                                    setInputValue('category', value);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={4} desktop={4}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>
                        <div className={styles.columnDiv}>
                            <label htmlFor="searchTags" className={styles.label}>Tags</label>
                            <p className={styles.tagsParagraph}>(we will limit them with 15 tags)</p>
                            <TagInput
                                className={styles.tagInput}
                                placeholder={"Type and press enter and the tag will be saved"}
                                setInputStateValue={(value) => {
                                    setInputValue("searchTags", value)
                                }}
                                value={queryData.searchTags ? queryData.searchTags : []}
                            />
                        </div>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={10} desktop={10}
                          sx={{
                              paddingLeft: 0,
                              paddingRight: 0,
                          }}>

                    </Grid>
                </Grid>
                <div className={styles.btnDiv}>
                    <button type="button" className={styles.cancelBtn} onClick={() => {
                        navigate("/");
                    }}>
                        Cancel
                    </button>
                    <div className={styles.submitBtnDiv}>
                        <button type="button" className={styles.saveBtn} onClick={handleSubmitForm}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
        ;
};

export default EditItemSection;