import styles from "./style.module.scss";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import { useState } from "react";

const CustomLoginInput = ({
    onChange,
    onClick,
    style,
    className,
    id,
    value,
    defaultValue,
    placeholder,
    type,
    name
}) => {
    const [inputType, setInputType] = useState(type);
    const handleTypeChange = () => {
        if(inputType==="password")
        {
            setInputType("text")
            return;
        }
        setInputType("password")
    }

    return(
        <div className={styles.customInputDiv } >
            <input onChange={onChange} onClick={onClick} style= {style} className= {type === "email" ? className + " " + styles.email_input :  className + " " + styles.customInput} id= {id} name= {name} value={value} defaultValue= {defaultValue} placeholder= {placeholder} type= {inputType} autoComplete="new-password"/>
            {type === 'password' && <span onClick={handleTypeChange}>{inputType === 'text' ? <VisibilityOff/> : <Visibility/> }</span>}
        </div>
    )
}


export default CustomLoginInput;