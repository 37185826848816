import styles from '../style.module.scss';

import {useNavigate} from "react-router-dom";

import { logoutSuccess } from '../../redux/features/user/userSlice';

import { useDispatch } from 'react-redux';

import settingsIcon from "../../assets/icons/layout/navbar/Settings.png";
import logoutIcon from "../../assets/icons/layout/navbar/Logout.png";
import orderIcon from "../../assets/icons/layout/navbar/OrdersMobile.png";
import homeIcon from "../../assets/icons/layout/navbar/homeIcon.png";

import { useRef, useState, useEffect } from 'react';

const Navbar = () => {


    const [myProfileDropped,setMyProfileDropped] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const dropdownRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!dropdownRef?.current?.contains(event.target)) {
                setMyProfileDropped(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
    return(
        <div className={styles.navbar}>
            <div className={styles.navbar_section}>
                <div className={styles.first_btn_div}>
                    <button className={styles.catalog_btn} onClick= {() => {
                        navigate("/add_new_item")
                    }}>
                        <span>Add new Item</span>
                    </button>
                    <button className={styles.home_btn} onClick= {() => {
                        navigate("/")
                    }}>
                        <img src={homeIcon} alt={"icon"}/>
                    </button>
                </div>
                <div className={styles.second_btn_div}>
                    <button className={styles.second_section_login_btn} onClick={() => {
                        navigate('./products')
                    }}>
                        Products
                    </button>
                    <div className= {styles.my_profile_div}>
                        <button className={styles.second_section_login_btn} onClick={() => {
                            setMyProfileDropped(!myProfileDropped)
                        }}>
                            My Profile
                        </button>
                        {myProfileDropped &&
                            <div className={styles.dropdown_menu} onClick={(e) => {e.stopPropagation()}} ref= {dropdownRef}>
                                <button className={styles.logOutBtn} onClick={() => {
                                    navigate('/orders_history');
                                    setMyProfileDropped(!myProfileDropped)
                                }}>
                                    <img src={orderIcon} alt={"orderIcon"}/>
                                    <span>History</span>
                                </button>
                                <button className={styles.logOutBtn} onClick={() => {
                                    navigate('/statistics');
                                    setMyProfileDropped(!myProfileDropped)
                                }}>
                                    <img src={orderIcon} alt={"orderIcon"}/>
                                    <span>Statistics</span>
                                </button>
                                <button className={styles.logOutBtn} onClick={() => {
                                    navigate('/profile_settings');
                                    setMyProfileDropped(!myProfileDropped)
                                }}>
                                    <img src={settingsIcon} alt={"settingIcon"}/>
                                    <span>Settings</span>
                                </button>
                                <button className={styles.logOutBtn} onClick={() => {
                                    dispatch(logoutSuccess());
                                    setMyProfileDropped(!myProfileDropped);
                                }}>
                                    <img src={logoutIcon} alt={"logOutIcon"}/>
                                    <span> Log Out</span>
                                </button>
                            </div>
                            }
                        </div>
                     
                </div>
            </div>
        </div>
    )
}



export default Navbar;