import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import {useSelector} from "react-redux";

import DefaultLayout from "./layout";
import LoginPage from "./pages/Login";
import AdminPage from "./pages/AdminPage";
import AddNewItem from "./pages/AddNewItem";
import EditItem from "./pages/EditItem";
import Orders from "./pages/Orders";
import OrderDetails from "./pages/OrderDetails";
import OrdersHistory from "./pages/OrdersHistory";
import Statistics from "./pages/Statistics";

export const useRoutes = () => {

    const isAuthenticated = useSelector((state) => state.isAuthenticated);

    return (
        <BrowserRouter>
            {!isAuthenticated ? (
                    <Routes>
                        <Route exact index element={<LoginPage/>}></Route>
                        <Route exact path='*' element={<Navigate to="/"/>}></Route>
                    </Routes>
                ) :
                <Routes>
                    <Route path="/" element={<DefaultLayout/>}>
                        <Route exact index element={<Orders/>}></Route>
                        <Route exact path='/add_new_item' element={<AddNewItem/>}></Route>
                        <Route exact path='/products' element={<AdminPage/>}></Route>
                        <Route exact path='/orders_history' element={<OrdersHistory/>}></Route>
                        <Route exact path='/edit_item' element={<EditItem/>}></Route>
                        <Route exact path='/statistics' element={<Statistics/>}></Route>
                        <Route exact path='/order_details' element={<OrderDetails/>}></Route>
                        <Route exact path='*' element={<Navigate to="/"/>}></Route>
                    </Route>
                </Routes>
            }
        </BrowserRouter>
    );
};