import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {toast} from "react-toastify";

const REMOVE_PRODUCT = gql`
    mutation Mutation(
        $removeProductInput: [ProductRemoveInput!]!
    ) {
        removeProduct(
            removeProductInput: $removeProductInput
        ) {
            _id
        }
    }
`;


const useRemoveItem = (productArray, refetch, resetSelectedArray) => {
    const newArray = [];
    for (let i = 0; i < productArray.length; i++){
        const obj = productArray[i];
        const keysToKeep = ['_id'];
        const filteredObject = keysToKeep.reduce((acc, key) => {
            if (obj && obj.hasOwnProperty(key)) {
                acc[key] = obj[key];
            }
            return acc;
        }, {});

        newArray.push(filteredObject)
    };


    const [removeProduct, {data, error, loading}] = useMutation(REMOVE_PRODUCT, {
        update(proxy){
            refetch();
            resetSelectedArray();
            toast.success("Success !! Product removed", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                toast.error(graphQLErrors[0].message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            } else {
                console.error('An unknown error occurred:', graphQLErrors);
            }
        },
        variables: { removeProductInput: newArray }
    });


    return{
        removeProduct,
        error,
        data,
        loading
    };
}
export default useRemoveItem;