import styles from './style.module.scss';

import emptyDesktop from "../../../assets/backgronds/noOrder/EmptyDesktop.png";


const NoOrder = ({lang}) => {

    return (
        <div className={styles.main_section}>

            <div className={styles.flex_row_divs}>
                <div className={styles.paragraph_div}>
                    <h1>
                        There are no orders yet
                    </h1>
                </div>
                <img src={emptyDesktop} alt={"img"}/>
            </div>
        </div>
    );
};

export default NoOrder;