import styles from "./style.module.scss";
import {useEffect, useState} from "react";
import upIcon from "../../../assets/icons/commonIcons/up.png";
import downIcon from "../../../assets/icons/commonIcons/down.png";
const OptionalDropdown = ({label, options, setInputStateValue, value}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(value ? value : '');

    const toggleDropdown = () => setIsOpen(!isOpen);

    useEffect(() => {
        if(value) setSelectedOptions(value);
    }, [value]);


    const handleOptionClick = (option) => {
        setSelectedOptions(option)
    };


    useEffect(() => {
        setInputStateValue(selectedOptions);
    }, [selectedOptions]);


    return (
        <div className={styles.dropdown}>
            <div className={styles.dropdown__toggle} onClick={toggleDropdown}>
                <label>{label}</label>
                <img src={isOpen ? upIcon : downIcon} alt={"dropIcon"} />
            </div>
            {isOpen && (
                <div className={styles.dropdown__option__div}>
                    {options.map((option,id) => (
                        <div
                            key={id}
                            className={styles.dropdown__option}
                            onClick={() => handleOptionClick(option)}
                        >
                            <label>{option}</label>
                            <input
                                className={styles.checkbox}
                                type="checkbox"
                                checked={selectedOptions.includes(option)}
                                readOnly
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default OptionalDropdown;
