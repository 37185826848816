import {useEffect, useState} from 'react';
import tagRemove from '../../../../assets/icons/commonIcons/tagRemove.png';
import styles from "./style.module.scss";
function TagInput({
                    value,    
                    placeholder,
                    className,
                    setInputStateValue,
                }) {
    const [inputValue, setInputValue] = useState('');
    const [valueArray, setValueArray] = useState([]);

    useEffect(() => {
        if(value) setValueArray(value);
    }, [value])

    function handleInputChange(event) {
        setInputValue( event.target.value );
    };

    function handleInputKeyDown(event) {
        if (event.key === 'Enter') {
            const tagText = inputValue.trim();
            if (tagText) {
                setValueArray([...valueArray, tagText]);
                setInputValue('');
            };
            event.preventDefault();
        };
    };

    function handleTagDelete(index) {
        const newTags = [...valueArray];
        newTags.splice(index, 1);
        setValueArray(newTags)
    };

    useEffect(() => {
        setInputStateValue(valueArray);
    }, [valueArray])


    return (
        <div className={styles.tagInput}>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                className={styles.input + " " + className}
                placeholder={placeholder}
            />
            <div className={styles.tagDiv}>
                {valueArray.map((tag, index) => (
                    <div key={index} className={styles.tag}>
                        <div>{"#" + tag}</div>
                        <button onClick={() => handleTagDelete(index)} className={styles.btn}>
                            <img src={tagRemove} alt={"remove"} />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TagInput;