import {Link, useLocation, useNavigate} from "react-router-dom";
import {useMutation} from "@apollo/react-hooks";
import {gql} from "graphql-tag";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";

import styles from './style.module.scss';

const CHANGE_ORDER_STATE = gql`
    mutation Mutation (
        $orderStateInput: ChangeStateInput
    ){
        changeOrderState(
          orderStateInput: $orderStateInput
        ){
            _id
        }       
    }   
   
`
const OrderDetailsSection = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [calculatedPrice, setCalculatedPrice] = useState(0);
    const {state} = useLocation();
    const navigate = useNavigate();

    const handleResize = () => {
        if (window.innerWidth < 800) {
            setIsMobile(true)
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        if (window.innerWidth < 800) {
            setIsMobile(true)
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    if (!state) {
        navigate("/");
    }
    const [changeProductState] = useMutation(CHANGE_ORDER_STATE, {
        update(proxy) {

        },
        onError({graphQLErrors}) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                toast.error(graphQLErrors[0].message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            } else {
                console.error('An unknown error occurred:', graphQLErrors);
            }
        },
        variables: {
            orderStateInput: {
                orderId: state._id,
            },
        },
    });

    useEffect(() => {
        if (state.orderState === 'new') {
            changeProductState();
        }
    }, []);

    useEffect(() => {
        let sum = 0;
        if (state) {
            for (let i = 0; i < state.productData.length; i++) {
                console.log(state.productData[i])
                sum = sum + state.productData[i].price
            }
            setCalculatedPrice(sum);
        }
    }, [state]);

    return (
        <div className={styles.order_details_section}>
            <div className={styles.link_menu}>
                <Link to={'/'}>
                    Home
                </Link>
                <Link to={'/orders'}>
                    Orders
                </Link>
            </div>
            <div className={styles.user_info_div}>
                <div className={styles.user_info_section + " " + styles.heading_div}>
                    <div>
                        Order Number:
                    </div>
                    <div className={styles.user_info_large_div}>
                        Date:
                    </div>
                    {!isMobile &&
                        <div className={styles.user_info_large_div}>
                            Email:
                        </div>
                    }

                    <div>
                        Total:
                    </div>
                    <div className={styles.user_info_large_div}>
                        Payment:
                    </div>
                </div>

                <div className={styles.user_info_section}>
                    <div>
                        {state.orderNumber}
                    </div>
                    <div className={styles.user_info_large_div}>
                        {state.deliveryTime}
                    </div>
                    {!isMobile &&
                        <div className={styles.user_info_large_div}>
                            {state.email}
                        </div>
                    }
                    <div>
                        {state.totalPrice}
                    </div>
                    <div className={styles.user_info_large_div}>
                        {state.paymentMethod}
                    </div>
                </div>
            </div>
            <div className={styles.product_section}>
                <div className={styles.section_heading}>
                    <h2>Order Details</h2>
                </div>
                <div className={styles.heading_span}>
                    {state.notes}
                </div>
                {state.productData && state.productData.map((item, id) => {
                    return (
                        <div key={id} className={styles.product_data}>
                             <span>
                                 <img src={item.productImages} alt={"productImage"} className={styles.product_img}/>
                            </span>
                            <span>
                                 <h3 className={styles.heading_span}>{item.productTitle.en}</h3>
                            </span>
                            <span>
                               <h3 className={styles.heading_span}>product state:</h3> {item.state}
                            </span>
                            <span>
                                <h3 className={styles.heading_span}>quantity:</h3> {item.quantity + "kg"}
                            </span>

                            <span>
                                <h3 className={styles.heading_span}>default price:</h3> {item.defaultPrice}
                            </span>
                            <span>
                               <h3 className={styles.heading_span}>price:</h3> {item.price}
                            </span>
                        </div>
                    )
                })}
                <div className={styles.product_price}>
                    Total product price : {calculatedPrice}
                </div>
            </div>
            <div>
                <div className={styles.section_heading}>
                    <h2>Shipping address</h2>
                </div>
                <div className={styles.shipping_address}>
                    <span>
                        {state.userName}
                    </span>
                    <span>
                        {state.address}
                    </span>
                    <span>
                        {state.tel}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default OrderDetailsSection;