import styles from './style.module.scss';
import {Link, useNavigate} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {useEffect} from "react";



const DELIVERED_ORDERS_QUERY = gql`
  query DeliveredOrders {
    deliveredOrders {
        _id
        userId
        productIds
        productData{
            productId
            price
            quantity
            defaultPrice
            productImages
            productTitle{
                en
            }
            state
        }
        totalPrice
        userName
        paymentMethod
        tel
        email
        region
        address
        deliveryTime
        notes
        orderNumber
    }
  }
`;



const OrdersSection = () => {

    const navigate = useNavigate();


    const {loading, error, data, refetch} = useQuery(DELIVERED_ORDERS_QUERY);




    useEffect(() => {
        refetch();

    }, [])


    if (loading) {
        return (
            <div> ... loading </div>
        );
    } else if (error) {
        return (
            <div> 😁 Error 😁 </div>
        );
    }

    return (
        <div className={styles.orders_section}>
            <h1 className={styles.section_heading}>
                Orders
            </h1>
            <Link to={'/'} className={styles.home_link}>
                back
            </Link>
            <div className={styles.cart_item_table_div}>
                <div className={styles.cart_item_heading_div}>

                    <div className={styles.cart_item_small_heading}>
                        Order №
                    </div>
                    <div className={styles.cart_item_small_heading}>
                        Region
                    </div>
                    <div className={styles.cart_item_big_heading}>
                        Order Image
                    </div>
                    <div className={styles.cart_item_small_heading}>
                        User Name
                    </div>
                    <div className={styles.cart_item_small_heading}>
                        Price
                    </div>
                    <div className={styles.cart_item_big_heading}>
                        Delivery Time
                    </div>

                </div>

                <div>
                    {!loading && data && data.deliveredOrders && data.deliveredOrders.map((item, id) => {
                        return (
                            <div
                                className={item.state === "new" ?
                                    styles.cart_item_div + " " + styles.new_cart_item_background
                                    :
                                    styles.cart_item_div
                                }
                                key={id}
                            >

                                <div className={styles.cart_item_small + " " + styles.cursor_pointer} onClick={() => {
                                    navigate("/order_details", {
                                        state: item,
                                    })
                                }}>
                                    {item.orderNumber}
                                </div>

                                <div className={styles.cart_item_small}>
                                    {item.region}
                                </div>
                                <div className={styles.cart_item_big}>
                                    {item.tel}
                                </div>
                                <div className={styles.cart_item_small}>
                                    {item.userName}
                                </div>
                                <div className={styles.cart_item_small}>
                                    {item.totalPrice}
                                </div>

                                <div className={styles.cart_item_big}>
                                    {item.deliveryTime}
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default OrdersSection;