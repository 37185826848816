import styles from "./style.module.scss"

import { useState } from "react";

import { useLoginUser } from "../../../hooks/login/useLoginUser"  

import CustoomLoginInput from "../../atom/CustomLoginInput";



const LoginSection = () => {
    const [values, setValues] = useState({
        username: '',
        password: ''
    });


    const changeHandler = (event) => {
        setValues({...values, [event.target.name]: event.target.value});
    };
    
    const handleLogin = () => {
        loginAdmin();
    };

    const {loginAdmin} = useLoginUser({values});
   
    return(
        <div className={styles.main_section_div}>
            <div className={styles.login_section}>
                <h1 className={styles.heading}>The Meat House Admin Panel</h1>
                <div className={styles.inputdiv}>
                    <label htmlFor= "username" className={styles.label}>Username</label><br/>
                    <CustoomLoginInput type="text" name="username" className={styles.loginInput} onChange= {changeHandler}/>
                </div>
                <div className={styles.inputdiv}>
                    <label htmlFor= 'password' className={styles.label}>Password</label><br/>
                    <CustoomLoginInput type="password" name="password" className={styles.loginInput} onChange= {changeHandler}/>
                </div>
                <button className={styles.recover_password}>
                    forgot password? 
                </button>
                
                <div className={styles.inputdiv}>
                    <button className={styles.login_btn} onClick={handleLogin} >Sign In</button>
                </div>
                
            </div>
        </div>
    );
};

export default LoginSection;
