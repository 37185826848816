import AddNewItemSection from "../../component/organism/AddNewItem";

const AddNewItem = () => {
    return(
        <div>
            <AddNewItemSection/>
        </div>
    );
};

export default  AddNewItem;