import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const UPDATE_PRODUCT_DATA = gql`
    mutation Mutation(
        $productDataInput: ProductDataInput
    ) {
        updateProductData(
            productDataInput: $productDataInput
        ) {
            _id
        }
    }
`;

const useProductDataUpdate = (values) => {
    const navigate = useNavigate();

    const [updateProductData, {loading}] = useMutation(UPDATE_PRODUCT_DATA, {
        update(proxy){
            navigate("/");
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                toast.error(graphQLErrors[0].message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            } else {
                console.error('An unknown error occurred:', graphQLErrors);
            }
        },
        variables: {
            productDataInput: values
        }
    });
    return {
        updateProductData,
        loading
    };
};

export default useProductDataUpdate;

