import styles from './style.module.scss';

import loginSectionImg from "../../../assets/backgronds/login/loginSectionImg.png";
import LoginSection  from '../../molecul/LoginPage/';

const SignInSection = () => {
 
   

    return(
        
        <div className={styles.signin_section}>
            <div className={styles.img_div}>
                <img src={loginSectionImg} alt={"section Img"}/>
            </div>
            <div className={styles.section_div}>
                <LoginSection />
            </div>
        </div>
    );
};

export default SignInSection;
