import styles from "./style.module.scss"
import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {toast} from "react-toastify";


const UPDATE_PRODUCT_STATUS = gql`
    mutation Mutation(
        $productStatusInput:[ProductStatusInput!]!
    ) {
        productStatusUpdate(
            productStatusInput: $productStatusInput
        ) {
            _id
        }
    }
`;


const StatusSwitch = ({ closePopup, refetchData, productArray, resetSelectedProduct}) => {
    const newArray = [];
    for (let i = 0; i < productArray.length; i++){
        const obj = productArray[i];
        const keysToKeep = ['_id', 'status'];
        const filteredObject = keysToKeep.reduce((acc, key) => {
            if (obj && obj.hasOwnProperty(key)) {
                acc[key] = obj[key];
            }
            return acc;
        }, {});

        newArray.push(filteredObject)
    };


    const [updateStatus] = useMutation(UPDATE_PRODUCT_STATUS, {
        update(proxy){
            refetchData();
            closePopup();
            resetSelectedProduct()
        },
        onError({graphQLErrors}){
            toast.error(graphQLErrors[0].message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        },
        variables: { productStatusInput: newArray }
    });


    return(
        <div className={styles.popupContainer} onClick={closePopup}>
            <div className={styles.popupBody} onClick={(e) => {e.stopPropagation()}} >
                <div>
                    <p>
                        Are you sure you want to make this item inactive?
                        This action will prevent the product from being used or displayed.<br/>
                        Do you want to continue?
                    </p>
                </div>
                <div className={styles.btnDiv}>
                    <button className={styles.confirmBtn} onClick={async () => {
                         await updateStatus();
                    }
                    }>Continue</button>
                    <button className={styles.cancelBtn} onClick={closePopup}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
export default StatusSwitch;