import {ApolloClient, createHttpLink, InMemoryCache, split} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from  '@apollo/client/link/error';
import { createStore } from '@reduxjs/toolkit';
import userReducer from './redux/features/user/userSlice';
import {getMainDefinition} from "@apollo/client/utilities";

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const httpLink = createHttpLink({
  uri: "https://server.meathouse.am/api/graphql",
});
const wsLink = new GraphQLWsLink(createClient({
    url: "wss://server.meathouse.am/subscription",
    options: {
        reconnect: true
    }
}));

// const httpLink = createHttpLink({
//   uri: "http://localhost:5000/graphql",
// });
//
// const wsLink = new GraphQLWsLink(createClient({
//     url: "wss://localhost:5000/graphql",
//     options: {
//         reconnect: true
//     }
// }));

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});


const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({ addTypename: false }),
});

const store = createStore(
    userReducer,
);

export { store, client };
