import StatisticsSection from "../../component/organism/Statistics";

const Statistics = () => {
    return(
        <div>
            <StatisticsSection />
        </div>
    );
};

export default Statistics;