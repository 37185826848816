import styles from "./style.module.scss";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {gql, useLazyQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {useNavigate} from "react-router-dom";

const ORDER_STATISTICS = gql`
  query OrdersStatistics ($start: [String!]!, $end: [String!]!, ){
    ordersStatistics(start: $start, end: $end){
       _id
        userId
        productIds
        productData{
            productId
            price
            quantity
            defaultPrice
            productImages
            productTitle{
                en
            }
            state
        }
        totalPrice
        userName
        paymentMethod
        tel
        email
        region
        address
        deliveryTime
        notes
        orderNumber
    }
  }
`;

const StatisticsSection = () => {
    const navigate = useNavigate();
    const [variables, setVariables] = useState({
        start: '',
        end: '',
    });
    const [mousePos, setMousePos] = useState({x: 0, y: 0});
    const [buttonPosition, setButtonPosition] = useState(0);
    const [totalSum, setTotalSum] = useState(0)
    useEffect(() => {
        const handleMouseMove = (e) =>
            setMousePos({x: e.clientX, y: e.clientY});
        document.addEventListener("mousemove", handleMouseMove);
        return () =>
            document.removeEventListener("mousemove", handleMouseMove);
    }, []);


    const [fetchData, {loading, error, data, refetch}] = useLazyQuery(ORDER_STATISTICS);

    useEffect(() => {
        if (!loading && !error && data) {
            let sum = 0
            data.ordersStatistics.map((order) => {
                sum = sum + order.totalPrice;
            });
            setTotalSum(sum);
        }
    }, [loading, error, data]);

    useEffect(() => {
        if(variables.start.length > 0 && variables.end.length > 0){
            setButtonPosition(0);
        }
    }, [variables]);

    return (
        <div className={styles.main_section}>
            <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker onChange={(value) => {
                        const fullDate = value.$d.toLocaleString().split(",")[0].split("/");
                        setVariables({...variables, start: fullDate});
                    }}/>
                    -
                    <DatePicker onChange={(value) => {
                        const fullDate = value.$d.toLocaleString().split(",")[0].split("/");
                        setVariables({...variables, end: fullDate});
                    }}/>
                </LocalizationProvider>
            </div>
            <button
                style={{
                    left: buttonPosition && `${buttonPosition}px`
                }}
                className={styles.fetch_btn}
                onMouseEnter={() => {
                    if (variables.start.length === 0 && variables.end.length === 0) {
                        const generatedNumber = Math.floor(Math.random() * 51) + 150;
                        setButtonPosition(buttonPosition + generatedNumber < 200 ? generatedNumber + buttonPosition : buttonPosition - generatedNumber)
                    }
                }}
                onClick={async () => {
                    if (variables.start.length > 0 && variables.end.length > 0)
                        await fetchData({variables: variables});
                }}
            >
                FETCH
            </button>
            {totalSum !== 0 &&
                <div className={styles.totalsum_div}>
                    TOTAL sum: {totalSum}
                </div>
            }

            <div className={styles.table_div}>
                <div className={styles.cart_item_heading_div}>
                    <div className={styles.cart_item_big_heading}>

                    </div>
                    <div className={styles.cart_item_small_heading}>
                        Order №
                    </div>
                    <div className={styles.cart_item_big_heading}>
                        Order Image
                    </div>
                    <div className={styles.cart_item_small_heading}>
                        User Name
                    </div>
                    <div className={styles.cart_item_small_heading}>
                        Price
                    </div>
                    <div className={styles.cart_item_big_heading + ' ' + styles.cursor_pointer}>
                        Delivery Time
                        <ImportExportIcon fontSize={'medium'}/>
                    </div>

                </div>


                {!loading && data && data.ordersStatistics && data.ordersStatistics.map((item, id) => {
                    return (
                        <div
                            className={item.orderState === "new" ?
                                styles.cart_item_div + " " + styles.new_cart_item_background
                                :
                                styles.cart_item_div
                            }
                            key={id}
                        >
                            <div className={styles.cart_item_big}>

                            </div>
                            <div className={styles.cart_item_small + " " + styles.cursor_pointer} onClick={() => {
                                navigate("/order_details", {
                                    state: item,
                                })
                            }}>
                                {item.orderNumber}
                            </div>
                            <div className={styles.cart_item_big}>
                                {item.tel}
                            </div>
                            <div className={styles.cart_item_small}>
                                {item.userName}
                            </div>
                            <div className={styles.cart_item_small}>
                                {item.totalPrice}
                            </div>

                            <div className={styles.cart_item_big}>
                                {item.deliveryTime}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default StatisticsSection;