import OrderDetailsSection from "../../component/organism/OrderDetails";

const OrderDetails = () => {
    return(
        <div>
            <OrderDetailsSection/>
        </div>
    );
};

export default OrderDetails;
