import SignInSection from "../../component/organism/Login";

const LoginPage = () => {
    return(
        <div>
            <SignInSection/>
        </div>
    );
};


export default LoginPage;