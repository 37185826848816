import EditItemSection from "../../component/organism/EditItem";

const AddNewItem = () => {
    return(
        <div>
            <EditItemSection/>
        </div>
    );
};

export default  AddNewItem;