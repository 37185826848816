import styles from "./style.module.scss"

import {Link, useLocation, useParams} from "react-router-dom";

function CustomBreadcrumbs() {
    const location = useLocation();

    let pathSegments = location.pathname.split('/').filter((x) => x);
    const idObj = useParams();
    const id = idObj.id;

    for(let i = 0; i < pathSegments.length; i ++){
        if(id === pathSegments[i]){
          pathSegments = pathSegments.slice(i - 1 , i)
        }
    }


    const breadcrumbs = pathSegments.map((segment, index) => {
        const link = `/${pathSegments.slice(0, index + 1).join('/')}`;
        const arr = segment.split("_");

        for(let i = 0; i < arr.length; i ++){
            const item = arr[i];
            const str = item.charAt(0).toUpperCase() + item.slice(1);
            arr[i] = str;
        }

        const pathName = arr.join(" ");
        return (
            <span key={link} className={styles.navLinkDiv}>
                <span className={styles.span}> > </span>
                <Link to={link} className={styles.link}>{pathName}</Link>
            </span>
        );
    });

    return (
        <nav className={styles.customBreadcrumbs}>
            <span>
                <Link to="/" className={styles.link}>Home</Link>
            </span>
                {breadcrumbs}
        </nav>
    );
}

export default CustomBreadcrumbs;
